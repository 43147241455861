import { render, staticRenderFns } from "./ProjectDetailsOverlay.vue?vue&type=template&id=49ca1f97&scoped=true"
import script from "./ProjectDetailsOverlay.vue?vue&type=script&lang=ts"
export * from "./ProjectDetailsOverlay.vue?vue&type=script&lang=ts"
import style0 from "./ProjectDetailsOverlay.vue?vue&type=style&index=0&id=49ca1f97&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ca1f97",
  null
  
)

export default component.exports