import ProjectData from '@/data/ProjectData.ts'

export default [
    new ProjectData("project-0", "Stranded Deep", "img/projects/StrandedDeep/DemoThumb.gif", `
    <div class="paragraph" text-align: justify;>
     <div style="text-align: justify;">
        <p style="font-size: 20px">an exciting survival game that plunges players into the depths of adventure, Take the role of a plane crash survivor stranded somewhere in the Pacific Ocean. Come face to face with some of the most life threatening scenarios that will result in a different experience each time you play. Scavenge. Discover. Survive.</p>
     </div>
    </div>
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/PS9v1rh7CfY" frameborder="0" allowfullscreen></iframe>
    </div>

    <div class="paragraph">
        <h2>My Contributions:</h2>
        <ul>
        <li>Implemented new features for the PS4 and Xbox One versions of the game, contributing to a significant increase in the game's popularity and user engagement.</li>
        <li>Optimized the game for better performance, resulting in faster load times and improved gameplay.</li>
        <li>Fixed over 60 bugs to ensure a seamless and immersive gaming experience.</li>
        </ul>
    </div>

    <div class="paragraph">
    <div class="notice">
        Available on <a href="https://store.steampowered.com/app/313120/Stranded_Deep/" target="_blank">Steam</a>,
         <a href="https://www.nintendo.com/us/store/products/stranded-deep-switch/" target="_blank">Nintendo</a>,
         <a href="https://store.playstation.com/en-us/concept/232519" target="_blank">Playstation Store</a> and
         <a href="https://www.xbox.com/en-US/games/store/stranded-deep/9nxnst9mkmk1" target="_blank">XBOX Store</a>.
    </div>
    </div>
    `, "#0051ff",true,true),
    new ProjectData("project-1", "Hypercasual Games", "img/projects/Hypercasual/HyperCasualThumb.gif", `
    <div class="paragraph">
     <div style="text-align: justify;">
      <p style="font-size: 20px"><font color="#FFFFFF">I created these games from scratch for several companies as a freelancer senior unity developer.</br>(Each game took 2-3 weeks to produce )</font>
      <font style="font-size: 0.9em">Some games have been removed from the store, for these you can get the .apk here but you'll need to <a href="https://www.androidauthority.com/how-to-install-apks-31494/">authorize third party apk installation</a>.</font></p>
      </div>
    </div>

    <hr>
    <div class="paragraph">
    <h2>Popsicle Stack (Android/IOS)</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/6zQJiVtxLNc" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <ul>
    <li>players collect popsicle molds and utilize different machines on the production line to create popsicles layer by layer.</li>
    <li>Implemented a unique approach to simulate the creation of popsicles, providing an engaging and interactive gameplay experience.</li>
  </ul>
    <div class="store-links">
      <a href="https://play.google.com/store/apps/details?id=com.northerncoldgames.popsiclestack&hl=en_US&gl=US" target="_blank">
        <img src="img/Icons/GooglePlayIcon.png" loading="lazy" width="120" alt="">
      </a>
      <span class="gap"></span>
      <a href="https://apps.apple.com/us/app/popsicle-stack/id1604459436" target="_blank">
        <img src="img/Icons/AppStoreIcon.png" loading="lazy" width="140" alt="">
      </a>
    </div>
  </div>
  
  <hr>
    <div class="paragraph">
      <h2>Shoot & Boom (Android/IOS)</h2>
      <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/kqvO7eD2cQE" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <ul>
      <li>Players hit enemies using various weapons, including bullets, knives, balloons, chickens, and arrows.</li>
      <li>Implemented different mesh-cutting approaches during runtime to achieve dynamic and realistic destruction effects.</li>
      <li>developed an IK (Inverse Kinematics) system to simulate the balloon effect for added visual impact.</li>
    </ul>
<br>
<div class="store-links">
  <a href="https://play.google.com/store/apps/details?id=com.anotherworld.shootandboom&hl=en&gl=US" target="_blank">
    <img src="img/Icons/GooglePlayIcon.png" loading="lazy" width="120" alt="">
  </a>

  <hr>
  <div class="paragraph">
    <h2>Heroes Assemble Protype (Android)</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/PlzDatf6x_U" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <ul>
    <li>featuring gate-based gameplay where players choose different heroes to fight against enemies.</li>
    <li>Implemented a robust architecture for managing heroes' attributes, skills, and upgrades using the Unity editor.</li>
    <li>This architecture allowed for easy customization and scalability of heroes throughout the game.</li>
  </ul>
<br>
<div class="store-links">
<a href="/apk/HeroAssembleDev.apk" target="_blank">
<img src="img/Icons/APKIcon.png" loading="lazy" width="70" alt="">
</a>

<hr>
<div class="paragraph">
<h2>Gymnastic Race (Android/IOS)</h2>
<div class="paragraph center">
  <iframe class="youtube" src="https://www.youtube.com/embed/pko33nZUeqI" frameborder="0" allowfullscreen></iframe>
</div>
</div>
<ul>
<li>Created a 3D platform runner game with gymnastic movements, offering dynamic gameplay mechanics.</li>
<li>Implemented a unique ribbon dynamic move that enhances character movement, providing a visually appealing and immersive experience.</li>
<li>Developed AI-controlled opponents to race against the player, and added competitive elements to the game.</li>
</ul>
<br>
<div class="store-links">
<a href="/apk/GymnasticRaceDev.apk" target="_blank">
<img src="img/Icons/APKIcon.png" loading="lazy" width="70" alt="">
</a>

<hr>
<div class="paragraph">
<h2>Match Beauty (Android/IOS)</h2>
<div class="paragraph center">
  <iframe class="youtube" src="https://www.youtube.com/embed/ZNnJn2rBr9A" frameborder="0" allowfullscreen></iframe>
</div>
</div>
<ul>
<li>Developed a drag-and-drop 3D game that challenges players to select appropriate clothing for different occupations.</li>
</ul>
<br>
<div class="store-links">
<a href="/apk/MatchBeautyDev.apk" target="_blank">
<img src="img/Icons/APKIcon.png" loading="lazy" width="70" alt="">
</a>

<hr>
<div class="paragraph">
<h2>Dress For Work (Android/IOS)</h2>
<div class="paragraph center">
  <iframe class="youtube" src="https://www.youtube.com/embed/jXhPkPAdKFE" frameborder="0" allowfullscreen></iframe>
</div>
</div>
<ul>
<li>Crafted a 3D platform runner game allowing players to choose different types of clothing for the character.</li>
<li>Designed an engaging and user-friendly interface that enhances the player's experience of selecting and customizing outfits.</li>
</ul>
<br>
<div class="store-links">
<a href="/apk/DressForWorkDev.apk" target="_blank">
<img src="img/Icons/APKIcon.png" loading="lazy" width="70" alt="">
</a>

</div>`, "#4FA5D8",true),
new ProjectData("project-2", "MixMob", "img/projects/MixMob/DemoThumb.gif", `
<div class="paragraph">
 <div style="text-align: justify;">
  <p style="font-size: 20px">a Solana-powered card strategy battle racing game developed by a team of veteran gaming developers.
  The game has received $7 million in funding from top crypto investors. You can let your creative inspiration run wild with MIXMOB's 
  unique encore remix culture that allows you to unleash your inspiration with the power of music, art, and fashion to create a one-of-a-kind gaming experience.</p>
  </div>
</div>
<div class="paragraph center">
    <iframe class="youtube" src="https://www.youtube.com/embed/us1r7QZ_A68" frameborder="0" allowfullscreen></iframe>
</div>

<div class="paragraph">
  <h2>My Contributions:</h2>
<ul>
  <li><strong>Leadership in Unity Development:</strong> Led a team in the development of a multiplayer gaming experience, resulting in a polished final product.</li>
  <li><strong>Integration of Photon Quantum Deterministic Network Engine:</strong> Implemented Photon Quantum Deterministic network engine to ensure smooth gameplay and resolved multiplayer bugs, enhancing the overall gaming experience.</li>
  <li><strong>Dynamic Scene Loading with Addressable:</strong> Utilized Addressable to dynamically load race scenes from a server, reducing load times and offering a seamless gaming experience.</li>
  <li><strong>Implementation of Unity DOTS for Enhanced Performance:</strong> Developed gameplay using Unity DOTS for improved performance and scalability.</li>
</ul>

<h3 class="centered-header">Multiplayer Test</h2>

<div class="paragraph center">
    <iframe class="youtube" src="https://www.youtube.com/embed/2nanEBIsSgI" frameborder="0" allowfullscreen></iframe>
</div>

</div>

<div class="paragraph">
    <div class="notice">
        Available on <a href="https://www.mixmob.io/" target="_blank">WebGL</a>.
    </div>
    </div>
`, "#d609bb",false,true),
    new ProjectData("project-3", "Survivor Z: Zombie Games", "img/projects/SurviveZ/DemoThumb.gif", `
    <div class="paragraph">
     <div style="text-align: justify;">
        <p style="font-size: 20px">Embark on an adrenaline-pumping journey of survival, enter the zombie survival games with intense action, shooting and looting with base expansion. Dive into a world overrun by zombies, where your survival skills will be put to the test as you navigate through the zombie games, loot for essential supplies, and upgrade your arsenal to face the survivor challenges ahead.</p>
     </div>
    </div>
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/3PtW5leLcKU" frameborder="0" allowfullscreen></iframe>
    </div>

    <div class="paragraph">
  <h2>My Contributions:</h2>
    <ul>
      <li><strong>Implemented new UIs:</strong> to enhance user experience.</li>
      <li><strong>Performed bug fixing:</strong> to ensure smooth operation of the system.</li>
      <li><strong>Auto Localization Data Importer/Extractor Tool:</strong>Implementation of a unity tool for efficient localization processes.</li>
      <li><strong>Auto Document Generator Tool:</strong> Implementation of a unity tool for Generate Documents of Selected classes through ChatGPT API.</li>
      <li><strong>Extracting Data Tool:</strong> Implementation of a unity tool for extracting all economy/balancing data from the game, facilitating management and adjustment of game dynamics.</li>
    </ul>
  </div>
  <div>

    <h3 class="centered-header">Auto Document Generator Tool</h2>

    <video controls>
        <source src="img/projects/AutoDocumentGenerator/AutoDocumentGenerator.mp4" type="video/mp4">
        Your browser does not support the video tag.
    </video>

    <style>
        video {
            height: 50%;
            width: 100%;
            object-fit: fill; // use "cover" to avoid distortion
            position: absolute;
        }
    </style>

    <h3 class="centered-header">Auto Localization Data Importer/Extractor Tool</h2>

    <video controls>
        <source src="img/projects/SurviveZ/AutoLocalizationDataTools.mp4" type="video/mp4">
        Your browser does not support the video tag.
    </video>

    <style>
        video {
            height: 50%;
            width: 100%;
            object-fit: fill; // use "cover" to avoid distortion
            position: absolute;
        }
    </style>

    </div>

    <div class="paragraph">
    <div class="notice">
        Available on <a href="https://play.google.com/store/apps/details?id=com.EternalStudio.SurvivorZ&hl=en_US&gl=US" target="_blank">Google Play</a> and <a href="https://apps.apple.com/us/app/survivor-z-loot-survive/id6476922970"_blank">App Store</a>.
    </div>
    </div>
  </div>
    </div>`, "#1c1c1c"),
    new ProjectData("project-4", "Solitaire - Classic Card Game", "img/projects/Solitaire/DemoThumb.gif", `
    <div class="paragraph">
     <div style="text-align: justify;">
      <p style="font-size: 20px">is by far the most popular one player card game with over 1 million users and 90000 daily active user.</p>
      </div>
    </div>
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/ljWITuE_MVc" frameborder="0" allowfullscreen></iframe>
    </div>

    <div class="paragraph">
      <h2>My Contributions:</h2>
      <ul>
        <li><strong>Optimization and Stability:</strong> Successfully improved game performance and resolved freezing issues by utilizing Profiler tools, memory profiler, and Android profiler. Conducted thorough analysis and implemented optimizations to enhance the overall gameplay experience. Additionally, created comprehensive unit tests to ensure long-term stability and smooth operation of the game.</li>
        <li><strong>Theme Customization:</strong> Developed a user-friendly theme customization feature, allowing players to personalize their gaming experience. Implemented options for changing the background, card front, and back within the settings menu, providing a visually customizable environment.</li>
        <li><strong>Winnable Deck Generator:</strong> Introduced an innovative winnable deck generator functionality, enhancing the gameplay experience for users. Implemented an algorithm that generates winnable card sets, adding an extra level of challenge and enjoyment to the game.</li>
        <li><strong>Force and Optional Update Flow:</strong> Enhanced the force and optional update flow within the game by implementing a target version selection mechanism. This improvement ensures users are smoothly guided through updates, maintaining a consistent and up-to-date gaming environment.</li>
        <li><strong>Build Automation Editor:</strong> Created a robust build automation editor tool, streamlining the process of building and releasing the game on platforms such as Amazon Appstore, Galaxy Store, and others. This tool effectively handles different SDKs and requirements, facilitating efficient and reliable deployment.</li>
        <li><strong>Integration with App Stores:</strong> Successfully implemented integration with Amazon Appstore, OneSignal, and Galaxy Store, ensuring seamless distribution and availability of the game across multiple platforms. This integration expands the reach of the game and improves accessibility for a wider audience.</li>
      </ul>
    </div>

    <div class="paragraph">
    <div class="notice">
        Available on <a href="https://play.google.com/store/apps/details?id=com.dna.solitaireapp&hl=en_US&gl=US" target="_blank">Google Play</a> and
        <a href="https://www.amazon.com/DNA-Mobile-Group-Solitaire/dp/B07CKGZ8ZB/ref=sr_1_1?dib=eyJ2IjoiMSJ9.7t2-ebDg6EFKaGQv9hr7_je1bLQ9t1VhNXRfwMCeFN8.rsv7y-GZo7AvUqd9QfReDWSS7Y42j4M2WeoSn12Y9ak&dib_tag=se&qid=1712189162&refinements=p_4%3ADNA+Mobile+Group&s=mobile-apps&sr=1-1" target="_blank">Amazon</a>.
    </div>
    </div>
    `, "#133d1e",false,true),
    new ProjectData("project-5", "House Master", "img/projects/QuantumJump/DemoThumb.gif", `
    
    <div class="paragraph">
     <div style="text-align: justify;">
      <p style="font-size: 20px"><font color="#FFFFFF">Developed Tools as a Plug-in for use in different apps of the Company, Used Application
        tier architecture, SOLID principle, and design patterns. all the apps of the company are related to Designing a house/store and decorate them.</font></p>
        </div>
    </div>

    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/4JoOtsZZamQ" frameborder="0" allowfullscreen></iframe>
    </div>

  <ul>
  <h3>Benchmark Tool:</h3>Designed and developed a benchmark tool that facilitates writing tests across Android, iOS, Windows, and WebGL platforms.</br> 
  <h3>Optimization:</h3>Conducted in-depth performance analysis and data gathering for the House Master app, identifying and resolving performance bottlenecks.
   Enabled sequential test execution and the saving of test results as a compressed file.</br> 
  <h3>Camera Joystick Controller:</h3>Enhanced an existing joystick controller, incorporating new features with an emphasis on easy integration with new apps.
    Employed the mediator pattern to seamlessly integrate the controller with various applications across the company's portfolio.</br> 
  <h3>Scene Lighting Tool:</h3>Engineered a versatile tool enabling users to create customizable light sources on walls and floors,</br> 
  with adjustable attributes such as type, color, intensity, and rotation. Utilized the Observer pattern to implement a messaging system for efficient communication.
    <h3>Mini-map Tool:</h3>Developed a mini-map tool for real-time positioning visualization, featuring automatic area detection, panning, zooming, teleportation, and distinctive object icons.
  </ul>
  <div class="paragraph">   
    <h2 class="centered-header">Benchmark Tool</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/STl8z9XxyJw" frameborder="0" allowfullscreen></iframe>
    </div>
    <h2 class="centered-header">QJ Camera Joystick Controller & Minimap Tool</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/0hrTC3FcYvg" frameborder="0" allowfullscreen></iframe>
    </div>
    <h2 class="centered-header">Mini-map Tool</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/YGsdWG8yW9g" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    
    </div>
    `, "#cc0000",false,false),
    new ProjectData("project-6", "VR Projects", "img/projects/VR/DemoThumb.gif", `
    
    <div class="paragraph">
     <div style="text-align: justify;">
      <p style="font-size: 20px"><font color="#FFFFFF">I Developed cross-platform VR games, delivering captivating and immersive experiences that engaged users on various platforms.</font></p>
      </div>
    </div>

    <div class="paragraph">
    <h2>HNI Smart Objectives VR</h2>
  </div>
  <ul>
    <li>Ported from Unreal engine to Unity engine</li>
    <li>Designed and developed a quiz-based game with unique mechanics allowing players to choose answers using weapons such as guns, bows, and lightsabers.</li>
    <li>Implemented an innovative blueprint script enabling the export of world objects to a JSON file, facilitating recreation within Unity.</li>
  </ul>
    
    <h3 class="centered-header">Gameplay</h3>

    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/7QvH-4humzA" frameborder="0" allowfullscreen></iframe>
    </div>

   <hr>
 <div class="paragraph">
    <h2> MR. Seha</h2>
  </div>
  <ul>
    <li>Developed an educational game targeting children to promote healthy eating habits, featuring two interactive sessions: Introduce Foods and Assessments.</li>
    <li>Creating integrated content by parsing data from an Excel file to dynamically generate sessions within Unity's editor.</li>
  </ul>
    
    <h3 class="centered-header">Nutritional Education Session 6 Gameplay</h2>

    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/vfhVkVkdNrg" frameborder="0" allowfullscreen></iframe>
    </div>
    
    <h3 class="centered-header">Nutritional Assessment</h2>

    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/toOElIiTUcc" frameborder="0" allowfullscreen></iframe>
    </div>
    
    <div class="paragraph">
    <h2>Ajman VR</h2>
  </div>
  <ul>
    <li>the development of interactive 360⁰ videos introducing company HR, providing an engaging and immersive experience.</li>
  </ul>
    
    <h3 class="centered-header">Overview</h2>

    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/JLnLS3kJAZY" frameborder="0" allowfullscreen></iframe>
    </div>
    
    </div>
    `, "#c53d00",false,true),
    new ProjectData("project-7", "Footy Foot (Soccer Card Game)", "img/projects/FootyFoot/DemoThumb.gif", `
    
    <div class="paragraph">
     <div style="text-align: justify;">
      <p style="font-size: 20px"><font color="#FFFFFF">A multiplayer online soccer card game featuring a WebGL application for managing purchasable cards and teams for clubs.
      The project was delivered in two phases (offline and online).</font></p>
      </div>
    </div>

    <div class="paragraph">
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/H33BTDBDjWc" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <h3>Team Management Tool:</h3>
  <ul>
    <li>Designed and developed a comprehensive team management tool encompassing the creation of players, teams, and cards.</li>
    <li>Devised an innovative approach to generate dynamic sprite sheets for players' and teams' logos during runtime, enhancing visual appeal and performance.</li>
    <li>Implemented a dual-view system, offering Unity Editor tools during the offline phase, and a database-based software solution during the online phase. 
    This allowed content managers within the development team to efficiently manage content while providing clubs with a seamless WebGL experience.</li>
  </ul>
  
   <h3>Optimization and Performance:</h3>
   <ul>
    <li>Crafted a lightweight and highly optimized scroll view mechanism, ensuring the best possible performance and swift loading times for users.</li>
  </ul>
  
  <h3>Online Phase Development:</h3>
  <ul>
    <li>Spearheaded the development of the online phase, integrating a Deterministic Network engine to facilitate seamless players' input transfer.</li>
  </ul>
  
  <h2 class="centered-header">Team Management Tool</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/DK4yHdTuqiU" frameborder="0" allowfullscreen></iframe>
    </div>
        
    `, "#53da6f",true,false),
    new ProjectData("project-8", "PixeLand", "img/projects/PixeLand/DemoThumb.gif", `
     
     <div class="paragraph">
      <div style="text-align: justify;">
      <p style="font-size: 20px"><font color="#FFFFFF">A captivating 2D and 3D pixel and voxel coloring-by-number game that introduces users to the world of creative coloring. This project involved the creation of a seamless pipeline to generate coloring models from a dedicated Voxels creator software, with game content crafted using a custom Unity Windows application.</font></p>
      </div>
    </div>

    <div class="paragraph">
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/Scnoz4wPBUE" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>  

  <h3>Coloring Model Content Creation Pipeline:</h3>
  <ul>
    <li>Engineered a sophisticated pipeline that seamlessly translated coloring models from a dedicated Voxels creator software into the game environment.</li>
    <li>Ensured optimal compatibility and visual fidelity, allowing users to experience accurate color application to pixel and voxel spaces.</li>
  </ul>
    
    <h3>Coloring Model Generation:</h3>
  <ul>
    <li>Developed a tailor-made Windows application within the Unity framework, facilitating efficient and streamlined content creation for the game.</li>
    <li>Empowered designers to craft intricate and engaging coloring experiences that captivate users.</li>
  </ul>
  
  <h3>Advanced Multithreaded Solution:</h3>
  <ul>
    <li>Employed a cutting-edge multithreaded solution for the game's coloring tools, enabling swift data retrieval from neighboring voxels.</li>
    <li>This approach significantly improved the performance and responsiveness of the coloring mechanics, enhancing the overall user experience.</li>
  </ul>
  
  <h2 class="centered-header">Model Content Creation Pipeline</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/xdHQ06CC8Es" frameborder="0" allowfullscreen></iframe>
    </div>
    
     <div class="paragraph">
    <div class="notice">
        Available on <a href="https://cafebazaar.ir/app/com.persianarts.pixeland?l=en" target="_blank">Android Local Market</a>.
    </div>
    </div>
  
    `, "#ea004f",false,false),
    new ProjectData("project-9", "Saychin", "img/projects/Saychin/DemoThumb.gif", `
    
    <div class="paragraph">
     <div style="text-align: justify;">
      <p style="font-size: 20px"><font color="#FFFFFF">An engaging and innovative project that lets players interact with intricate and distorted objects to create compelling shadows and capture authentic silhouettes.</font></p>
      </div>
    </div>

    <div class="paragraph">
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/5JFQTMnGmss" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>  
    
  <h3>High-Quality Shadow Innovation:</h3>
  <ul>
    <li>Designed an innovative solution for generating high-quality shadows even without direct lighting, enhancing the visual appeal and realism of the game.</li>
  </ul>
  
  <h3>Unity Editor Tools for Content Creation:</h3>
  <ul>
    <li>Crafted user-friendly Unity editor tools that dynamically generate and version asset bundles, simplifying content creation and maintenance.</li>
    <li>Implemented seamless integration with server uploads, ensuring the accessibility of updated content for players.</li>
  </ul>
  
  <h3>Dynamic Game Menu and Downloadable Content with Asset Bundle:</h3>
  <ul>
    <li>Engineered a dynamic game menu that offers downloadable content, enhancing replayability and player engagement.</li>
  </ul>
  
  <h3>Performance Optimization for Accessibility:</h3>
  <ul>
    <li>Engineered strategic code optimizations and asset enhancements, enabling the game to operate smoothly on low-end Android devices.</li>
    <li>Ensured broad accessibility by delivering a high-quality gaming experience regardless of device specifications.</li>
  </ul>
   
  <h2 class="centered-header">Bundle Creation Editor Tool:</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/tbxvkvoTJDc" frameborder="0" allowfullscreen></iframe>
    </div>
    
    <div class="paragraph">
    <div class="notice">
        Available on <a href="https://cafebazaar.ir/app/ir.rashin.saychin?l=en" target="_blank">Android Local Market</a>.
    </div>
    </div>
  
    </div>
    `, "#9400ea",false,false),
    new ProjectData("project-10", "Soccer Champion(Fútbol Stars)", "img/projects/SoccerChampion/DemoThumb.gif", `
    
    
     <div class="paragraph">
      <div style="text-align: justify;">
           <p style="font-size: 20px"><font color="#FFFFFF">Finger soccer multiplayer game for PC and mobile devices.</font></p>
      </div
    </div>

    <div class="paragraph">
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/e9WaLZvJA54" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>  
    
  <h3>Multi-Market In-App Purchases:</h3>
  <ul>
    <li>Implemented a robust in-app purchases system across multiple markets, with server-side validation ensuring secure transactions and a seamless user experience.</li>
  </ul>
    
    <h3>Build Tool Development:</h3>
  <ul>
    <li>Developed efficient build tools to manage multi-market releases, automating code generation tailored for specific markets and optimizing distribution processes.</li>
  </ul>
  
  <h3>Audio Management Enhancement:</h3>
  <ul>
    <li>Engineered audio management tools to facilitate the addition of new sounds, including a distinctive announcer, enhancing the game's auditory experience.</li>
  </ul>
  
   <h3>Prediction Mechanism:</h3>
  <ul>
    <li>Implemented a dynamic prediction segment that retrieves a list of soccer matches from a server and visually represents them in the UI.</li>
    <li>Created an interactive feature where players can predict match outcomes based on match time, with results revealed after matches conclude.</li>
  </ul>
    
 <h3>In-Game Tutorial Creation:</h3>
  <ul>
    <li>Designed and developed a user-friendly Unity editor tool specifically for creating in-game tutorials, ensuring ease of content creation and an enhanced player learning experience.
    </li>
  </ul>
   <div class="paragraph">
    <div class="notice">
        Available on <a href="https://store.steampowered.com/app/1036930/PC_Ftbol_Stars/" target="_blank">Steam</a>.
    </div>
    </div>
    `, "#e4a900",false,false),
    new ProjectData("project-11", "Overtaking and Award", "img/projects/OvertakingAndAward/DemoThumb.gif", `

 <div class="paragraph">
  <div style="text-align: justify;">
     <p style="font-size: 20px"><font color="#FFFFFF">Multiplayer Highway Racer Mobile Game A dynamic mobile game that combines high-speed racing with competitive multiplayer features.</font></p>
     </div>
    </div>

    <h2 class="centered-header">Multiplayer Test</h2>
    <div class="paragraph">
    <div class="paragraph center">
        <iframe class="youtube" src="https://www.youtube.com/embed/9-05o6edBKs" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>  

  <h3>Highway Racer Integration:</h3>
  <ul>
    <li>Incorporated elements from the Highway Racer complete project, providing a strong foundation for the game's mechanics and visuals.</li>
  </ul>
    
    <h3>Real-time Multiplayer Implementation:</h3>
  <ul>
    <li>Developed a real-time multiplayer segment using Photon Unity Networking (PUN), allowing players to engage in exhilarating races against each other.</li>
    <li>Enhanced the gameplay experience by enabling seamless multiplayer interactions and intense competition on the highway.</li>
  </ul>
  
  <h3>AI Steering Development:</h3>
  <ul>
    <li>Engineered AI steering mechanics that imbue computer-controlled vehicles with dynamic and responsive behavior.</li>
    <li>Elevated the challenge and excitement of single-player races by ensuring AI opponents adapt to player actions.</li>
  </ul>
 
    </div>
    </div>
    `, "#818f00",false,true),
    new ProjectData("project-12", "Flash Games", "img/projects/FlashGames/DemoThumb.gif",
        `<div class="paragraph">
 <div style="text-align: justify;">
       <p style="font-size: 20px"><font color="#FFFFFF"> developed a diverse portfolio of more than 20 games tailored for kids. 
       offering kids an opportunity to participate and compete in diverse challenges.</font></p>
       </div>
    </div>

    <hr>
    <div class="paragraph">
    <h2>Puzzle Pieces Game:</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/kWHbL92V3SE" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <ul>
    <li>Engineered a dynamic runtime puzzle creation system, generating puzzle pieces from input images.</li>
  </ul>
   
   <hr>
    <div class="paragraph">
    <h2>Colors Memory Game:</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/XC1O7AdQwyo" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <ul>
    <li>Orchestrated the development of game animations using code, leveraging the TweenMax library for seamless and visually captivating in-game animations.</li>
  </ul>
   
    <hr>
    <div class="paragraph">
    <h2>Guessing a Word from Animation:</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/ls9zYKYP3Cw" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <ul>
    <li>Produced highly optimized content for an engaging gameplay experience.</li>
    <li>Devised a unique approach to gameplay involving the random presentation of letters on a blackboard, coupled with drawing animations.</li>
  </ul>
   
   <hr>
    <div class="paragraph">
    <h2>Reminding Shown Number:</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/USWFqlZYiPo" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <ul>
    <li>Utilized code-driven animations, employing TweenMax, to bring visual dynamism to the process of reminding and displaying numbers within the game.</li>
  </ul>
   
   <hr>
    <div class="paragraph">
    <h2>Math Operation Game:</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/VoYDhNXNEYg" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <ul>
    <li>Implemented a math operation game by generating random math challenges, prompting players to guess the correct operation.</li>
  </ul>
   
    <hr>
    <div class="paragraph">
    <h2>Sudoku:</h2>
    <div class="paragraph center">
      <iframe class="youtube" src="https://www.youtube.com/embed/C6PV823pz0A" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <ul>
    <li>Implemented an algorithm for random generating a Sudoku table based on the row/col number.</li>
  </ul>
   
  </div>
`, "#632700",false),
];