import ProjectData from '@/data/ProjectData.ts'

export default [
    new ProjectData("code-0", "Tower Color", "img/CodePortfolio/TowerOfColor.gif", `
    
    <div class="paragraph">
        <h2>My Contributions:</h2>
        <ul>
        <li><strong>Optimize the project and reduce the batches</strong></li>
        <li><strong>Implemented a Mission System with Reward System.</strong></li>
        </ul>
    </div>

     <div class="paragraph center">
     <h3 class="centered-header">Reduce Draw Calls</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/OZY6aPJodYk" frameborder="0" allowfullscreen></iframe>
      <br>
      <h3 class="centered-header">Mission and Reward System</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/-UsCjWDpKtk" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>

    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/ToC" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
    
    </div>
    `, "#00aeff"),

    new ProjectData("code-1", "Baloot Card Game", "img/CodePortfolio/BalootCardGame.gif", `
    
    <ul>
        <li><strong>Implement a Baloot Card game and a simple theme customization</strong></li>
    </ul>
    
     <div class="paragraph center">
     <h3 class="centered-header">Gameplay</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/Gr0BmHi-Bwk" frameborder="0" allowfullscreen></iframe>
      <br>
      <h3 class="centered-header">Theme Customization</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/XklXKGjQ5Wc" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/BalootCardGame" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
  
    </div>
    `, "#955f00"),

    new ProjectData("code-2", "Ludo Board Game", "img/CodePortfolio/LudoBoardGame.gif", `
    
    <ul>
        <li><strong>Implemented a part of Ludo Board game.</strong></li>
    </ul>
    
     <div class="paragraph center">
      <h3 class="centered-header">Gameplay</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/_b4yToZ-Nqo" frameborder="0" allowfullscreen></iframe>
      <br>
      <h3 class="centered-header">Autoplay for test and debug</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/-E1k4_JFnT8" frameborder="0" allowfullscreen></iframe>
      <br>
      <h3 class="centered-header">Unity Editor Tool For Create Board Paths</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/VjrFtiZYC48" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/LudoBoardGame" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
  
    </div>
    `, "#bf0000"),

    new ProjectData("code-3", "3D AI for a Stealth Game", "img/CodePortfolio/3DAIStealthGame.gif", `
    
    <ul>
        <li><strong>Implemented an AI be able to Patrolling, Player Detection, Chasing and Sound Detection.</strong></li>
        <li><strong>The NPC behaviors are designed to be modular and reusable</strong></li>
    </ul>
    <div class="paragraph center">
      <h3 class="centered-header">Gameplay</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/Bb9XLYVj2BY" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/AIForStealthGame" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
  
    </div>
    `, "#6c7375"),

    new ProjectData("code-3", "PD Assessment Test", "img/CodePortfolio/DailyBonus.gif", `
    
    <ul>
        <li><strong>Implemented an Unity Editor tool to automatically configure prefabs in the project.</strong></li>
        <li><strong>Implemented a Lucy Wheel daily bonus mini-game.</strong></li>
        <li><strong>Designed an Architecture of simple Minesweeper game using MVP.</strong></li>
    </ul>
    <div class="paragraph center">
        <h3 class="centered-header">Unity Editor tool to automatically configure prefabs</h3>
        <iframe class="youtube" src="https://www.youtube.com/embed/WK7JwM2L1O8" frameborder="0" allowfullscreen></iframe>
        <br>
        <h3 class="centered-header">Lucy Wheel daily bonus mini-game</h3>
        <iframe class="youtube" src="https://www.youtube.com/embed/UExpoKfdtwQ" frameborder="0" allowfullscreen></iframe>
        <br>
        <h3 class="centered-header">Architecture of simple Minesweeper game</h3>
        <img id="classDiagram" src="img/projects/CodeChallenge/PD/ClassDiagram.jpg" loading="lazy" width="680" alt="">
    </div>
</div>
<div class="Source Link">
    <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/PDAssessmentTest" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
    </a>
</div>
  
    </div>
    `, "#0071dc"),
    new ProjectData("code-4", "Drop The Ball", "img/CodePortfolio/DropTheWheel.gif", `
    
    <ul>
        <li><strong>Developed a Clone the Helix Jump game mechanic in one day in the unity as a code assessment</strong></li>
        <li><strong>Implementation of a Unity Editor Tool For Create Levels</strong></li>
    </ul>
    <div class="paragraph center">
      <h3 class="centered-header">Gameplay</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/jCoAHTjV38g" frameborder="0" allowfullscreen></iframe>
      
      <h3 class="centered-header">Unity Editor Tool for Add/Modified Level</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/U226a1yfAVM" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/DropTheBall" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
  
    </div>
    `, "#8e007b"),

    new ProjectData("code-5", "Spiral Roll Clone", "img/CodePortfolio/SpiralRollClone.gif", `
    
    <ul>
        <li><strong>Developed a Clone the Spiral Roll game mechanic in the unity as a code assessment</strong></li>
    </ul>
    <div class="paragraph center">
      <h3 class="centered-header">Gameplay</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/XLsV2_FFNd0" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/SpiralRollClone" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
  
    </div>
    `, "#00444a"),

    new ProjectData("code-6", "Jump Race Clone", "img/CodePortfolio/JumpRaceClone.gif", `
    
    <ul>
        <li><strong>Developed a Clone the Jump Race game mechanic in the unity as a code assessment</strong></li>
    </ul>
    <div class="paragraph center">
      <h3 class="centered-header">Gameplay Blue Sky Theme</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/3qXMtlWwC-E" frameborder="0" allowfullscreen></iframe>
      <h3 class="centered-header">Gameplay UFO Theme</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/aLtdY2DTcg4" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/JumpRaceClone" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
    </div>
    `, "#ae0000"),

    new ProjectData("code-7", "Stack Runner", "img/CodePortfolio/StackRunner.gif", `
    
    <ul>
        <li><strong>Developed a Cube Surfer clone in the unity as a code assessment</strong></li>
    </ul>
    <div class="paragraph center">
      <h3 class="centered-header">Gameplay</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/KkTX-0uEiHQ" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/StackRunner" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
  
    </div>
    `, "#ae7700"),

    new ProjectData("code-8", "IM Skills Challenge", "img/CodePortfolio/IMSkillsChallenge.gif", `
    
    <ul>
        <li><strong>I created APIs as unity UI to test them on different platforms.</strong></li>
        <li><strong>I create Unit Test in edit mode for test and validate the server response JSON.</strong></li>
        <li><strong>I created unity UI in 3d scene and send a login request to a server and show the server response on it.</strong></li>
        <li><strong>I created an Editor window in "Custom/APIs Data To Scene Window" to send a request to the server and show the response in a scene UI elements on edit mode, I get all of the requests has Expose attribute with reflection from APIs class and create Editor elements with them.</strong></li>
    </ul>
    <div class="paragraph center">
      <h3 class="centered-header">Test Created designed APIs in Unity</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/NrUhsbxx66o" frameborder="0" allowfullscreen></iframe>
       <h3 class="centered-header">Unit Test</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/VpLRsJ-_nvQ" frameborder="0" allowfullscreen></iframe>
       <h3 class="centered-header"> UI in 3D Scene</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/hDCkmdhuqx0" frameborder="0" allowfullscreen></iframe>
       <h3 class="centered-header">Custom/APIs Data To Scene Editor</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/5MqbEeMFIu4" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/IMSkillsChallenge" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
  
    </div>
    `, "#00789c"),
    new ProjectData("code-9", "Lightsaber Parry 3D", "img/CodePortfolio/LightsaberParry3D.gif", `
    
    <ul>
        <li><strong>Developed the game based on test assessment documentation.</strong></li>
    </ul>
    <div class="paragraph center">
      <h3 class="centered-header">Gameplay</h3>
      <iframe class="youtube" src="https://www.youtube.com/embed/5qEfy6E8LnQ" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
    <div class="Source Link">
      <a href="https://github.com/RezaRahimi2/CodeChallenges/tree/LightsaberParry3D" target="_blank">
        <img src="img/Icons/GithubIcon.png" loading="lazy" width="120" alt="">
      </a>
    </div>
  
    </div>
    `, "#732e00")
];